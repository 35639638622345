<template>
    <input
        v-model="value"
        v-bind="getField(field).getInputBindings()"
        type="hidden"
    >
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({ type: String, required: true });
</script>
